<template>
  <div>
    <div class="container">
      <div class="search-tab-container is-flex mt-5 py-4">
        <router-link
          class="search-tab"
          :class="{ 'selected-tab': searchType === 'web' }"
          :to="`/results?q=${query}`"
          >Web</router-link
        >
        <router-link
          class="search-tab"
          :class="{ 'selected-tab': searchType === 'images' }"
          :to="`/results/images/?q=${query}`"
          >Images</router-link
        >
        <router-link
          class="search-tab"
          :class="{ 'selected-tab': searchType === 'video' }"
          :to="`/results/video/?q=${query}`"
          >Videos</router-link
        >

        <div class="latest">
          <router-link
            class="search-tab"
            :class="{ 'selected-tab': searchType === 'news' }"
            :to="`/results/news/?q=${query}`"
            >Latest</router-link
          >
          <span class="tooltip">See alternate search results</span>
        </div>
      </div>
    </div>

    <div :class="{ container: searchType !== 'images' }">
      <div class="mb-6">

        <!--<div 
          v-if="searchType === 'web'"
          class=" ad-leaderboard">
          <div
            id="banner1"
            :ad-account="$settings.INVOKE_ENGAGE_ID"
            width="320"
            height="50"
            ad-client="id-1"
            ad-campaign="id-1">
          </div>
        </div>-->

        <cse-results
          ref="gcse"
          v-show="selectedSource === sources.EXT"
          :search-type="searchType"
          :query="query"
        />

        <internal-web-results
          ref="internal"
          v-show="selectedSource === sources.INT"
          @no-results="showFallBackResults"
        />

        <video-results ref="video" v-show="selectedSource === sources.VID" />



        <div class="results-sidebar is-block" v-if="searchType === 'web'">
          <external-link
            :href="$settings.UNSTOPPABLE_LINK"
            target="_blank"
            
            ><img
              width="300"
              :src="require('@/assets/images/PLUMB_Unstoppable_sml.png')"
              alt="Unstoppable Domains"
          /></external-link>
          <div class="ad-sidebar-container">
            <!-- <external-link class="ad-info is-size-7 has-text-centered is-block mt-2 mb-1" href="https://depths.plumb.one/blog/click-to-earn-privacy-ads-overview/ ">Click-to-earn Privacy ads
            <br>What <em>are</em> they?</external-link>

            <div
              id="banner2"
              class="ad-sidebar"
              :ad-account="$settings.INVOKE_ENGAGE_ID"
              width="300"
              height="250"
              ad-client="id-1"
              ad-campaign="id-1">
            </div>   -->
          </div>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import CseResults from "@/components/CseResults";
import InternalWebResults from "@/components/InternalWebResults";
import VideoResults from "@/components/VideoResults";

export default {
  name: "Results",

  props: {
    searchType: {
      type: String,
      default: "web",
    },
    query: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    loading: false,
    selectedSource: null,
    sources: {
      INT: "internal",
      EXT: "gcse",
      VID: "video",
    },
  }),

  components: { CseResults, InternalWebResults, VideoResults },

  methods: {
    showFallBackResults() {
      this.selectedSource = this.sources.EXT;
      this.$refs.gcse.renderCSE(this.query, this.searchType);
    },
    setSource() {
      switch (this.searchType) {
        case "images":
          this.selectedSource = this.sources.EXT;
          this.$refs.gcse.renderCSE(this.query, this.searchType);
          break;
        case "video":
          this.selectedSource = this.sources.VID;
          this.$refs.video.renderResults(this.query);
          break;
        case "news":
          this.selectedSource = this.sources.EXT;
          this.$refs.gcse.renderCSE(this.query, this.searchType);
          break;
        default:
          this.selectedSource = this.sources.INT;
          this.$refs.internal.renderResults(this.query);
      }
    },
    // initAds(){
    //   const INVOKE_DISPLAY_TIMEOUT = 200;
    //   this.loadAdsScript();
    //   const adDisplayInterval = setInterval(() => {
    //     const invoke = window.invokeads;
    //     if(invoke){
    //       //invoke.loadAdById({"id":"banner1","width":"320px","height":"50px"});
    //       invoke.loadAdById({"id":"banner2","width":"300px","height":"250px"});
    //       clearInterval(adDisplayInterval);
    //     }
    //   }, INVOKE_DISPLAY_TIMEOUT)
    // },
    // loadAdsScript(){
    //   try {
    //     this.$loadScript(this.$settings.INVOKE_ENGAGE_URL);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
  },

  mounted() {
    //this.initAds();
    this.setSource();
  },

  watch: {
    $route() {
      //this.initAds();
      this.setSource();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

.ad-leaderboard{
  max-width: 728px;
  display: block;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  margin-bottom: 16px;
}

.ad-mobile{
  max-width: 272px;
  display: block;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  margin-bottom: 16px;
}

.ad-sidebar-container {
  max-width: 272px;
  display: block;
  overflow: hidden;
  margin-bottom: 16px;
}

.ad-sidebar{
  border: 1px solid #e7e7e7;
}

.results-sidebar {
    position: absolute;
    left: 810px;
    width: 272px;
    top: 0;
    z-index: 5;
  @media screen and (max-width: 1200px) {
    position: relative;
    top: 0;
    left: 0;
    margin: 40px auto;
    width: 300px;
  }
}

.search-tab {
  color: $tertiary;
  margin-right: 21px;
  font-size: 21px;
  font-weight: 500;
  @media screen and (max-width: 430px) {
    margin-right: 15px;
    font-size: 18px;
  }
}
.selected-tab {
  color: $secondary;
  font-weight: 600;
  border-bottom: 3px solid $secondary;
}

.tooltip {
  display: none;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 2;
}
@media (hover: hover) {
  .latest:hover .tooltip {
    display: inline-block;
  }
}
</style>


<template>
  <div>
    <b-loading v-model="loading"></b-loading>
    <web-search-skeleton v-if="loading && searchType !== 'images'" />

    <!--
        <div v-if="searchType === 'images'">
          <b-field grouped>
              <b-select v-model="selectedImageSize" placeholder="Size">
                  <option
                      v-for="option in imageSizeOpts"
                      :value="option.value"
                      :key="option.value">
                      {{ option.label }}
                  </option>
              </b-select>
          </b-field>
        </div>
        -->

    <div class="latest-text" v-if="searchType === 'news'">
      <span>Here are the latest results from all around the web!</span>
      <br />
    </div>

    <div class="cse-container" ref="cse-target"></div>

    <div class="container">
      <b-pagination
        class="pt-4"
        v-if="cseRendered"
        :total="totalItems"
        v-model="currentPage"
        range-before="1"
        range-after="4"
        :per-page="perPage"
        icon-prev="chevron-left"
        icon-next="chevron-right"
        @change="pageChanged"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import WebSearchSkeleton from "@/components/WebSearchSkeleton";

const CSE_URL = process.env.VUE_APP_CSE_URL;
const GNAME = "g-search-res";

export default {
  name: "CseResults",

  data: () => ({
    loading: false,
    cseRendered: false,
    cseReady: false,
    currentPage: 1,
    perPage: 10,
    searchType: "web",
    query: null,
    selectedImageSize: null,
    totalPages: 0,
    imageSizeOpts: [
      { label: "Icon", value: "icon" },
      { label: "Small", value: "small" },
      { label: "Medium", value: "medium" },
      { label: "Large", value: "large" },
      { label: "XLarge", value: "xlarge" },
      { label: "XXLarge", value: "xxlarge" },
      { label: "HUGE", value: "huge" },
    ],
    imageTypeOpts: [
      { label: "Clip Art", value: "clipart" },
      { label: "Face", value: "face" },
      { label: "Line Drawings", value: "lineart" },
      { label: "Stock Photos", value: "stock" },
      { label: "Photos", value: "photo" },
      { label: "Animated", value: "animated" },
    ],
    imageFileTypeOpts: [
      { label: "Jpeg", value: "jpg" },
      { label: "gif", value: "gif" },
      { label: "png", value: "png" },
      { label: "bmp", value: "bmp" },
      { label: "svg", value: "svg" },
      { label: "webp", value: "webp" },
      { label: "ico", value: "ico" },
      { label: "raw", value: "raw" },
    ],
  }),

  components: {
    WebSearchSkeleton,
  },
  methods: {
    pageChanged(page) {
      this.$eventBus.$emit("scrollToTop");
      document
        .querySelectorAll(".gsc-resultsbox-visible .gsc-cursor-page")
        [page - 1].click();
    },
    clearCSE() {
      if (this.cseRendered) {
        google.search.cse.element.getElement(GNAME).clearAllResults();
        this.loading = false;
        this.cseRendered = false;
        ///this.totalItems = 0;
        this.currentPage = 1;
      }
    },
    renderCSE(query, searchType = null) {
      if (!this.cseReady) {
        setTimeout(() => {
          this.renderCSE(query, searchType);
        }, 250);
      } else {
        this.searchType = searchType;
        this.query = query;
        this.loading = true;
        this.clearCSE();
        try {
          google.search.cse.element.render({
            div: this.$refs["cse-target"],
            tag: "searchresults-only",
            gname: GNAME,
            attributes: {
              autoSearchOnLoad: false,
              mobileLayout: "disabled",
              defaultToImageSearch: searchType === "images",
              adtest: "off",
              sort_by: searchType === "news" ? "date" : null,
              //imageSearchResultSetSize: this.selectedImageSize
              //defaultToRefinement: searchType === 'news' ? process.env.VUE_APP_CSE_NEWS_REFINEMENT_ID : false
              //imageSearchLayout: 'popup'
            },
          });
          google.search.cse.element.getElement(GNAME).execute(query);
        } catch (err) {
          this.loading = false;
          console.error(err);
        }
      }
    },

    renderCSECallback() {
      //let totalPages = document.querySelectorAll('.gsc-cursor-page').length;
      //this.totalItems = totalPages / this.perPage;
      //this.$matomo && this.$matomo.trackEvent('GCSE', 'results_rendered');
      this.$matomo && this.$matomo.trackEvent('searchResults', 'resultsRendered', 'Web', 'Google');
      this.totalPages = document.querySelectorAll(
        ".gsc-resultsbox-visible .gsc-cursor-page"
      ).length;
      this.loading = false;
      this.cseRendered = true;
    },

    initCSE() {
      window.__gcse = {
        parsetags: "explicit", // Defaults to 'onload'
        initializationCallback: () => {
          //this.renderCSE();
          this.cseReady = true;
        },
        searchCallbacks: {
          image: {
            starting: () => {
              this.loading = true;
            },
            //ready: myImageResultsReadyCallback,
            rendered: () => {
              this.renderCSECallback();
            },
          },
          web: {
            starting: () => {
              this.loading = true;
            },
            //ready: myWebResultsReadyCallback,
            rendered: () => {
              this.renderCSECallback();
            },
          },
        },
      };

      try {
        this.$loadScript(CSE_URL);
      } catch (err) {
        console.error(err);
      }
    },
  },

  computed: {
    totalItems() {
      return this.totalPages * this.perPage;
    },
  },

  watch: {
    selectedImageSize() {
      this.renderCSE(this.query, this.searchType);
    },
  },

  mounted() {
    this.initCSE();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

.latest-text {
  color: $tertiary;
  font-size: 18px;
  font-weight: 600;
  margin-top: -1em;
  margin-bottom: 30px;
}
.cse-container {
  min-height: 800px;
}
br {
  display: none;
}
</style>
<template>
  <div class="video-results-container">
    <section>
      <div class="mt-4 mb-5">
        <div class="sort-options" v-if="items.length">
          <b-field horizontal label="Sort by" custom-class="is-small">
            <b-select placeholder="Select" v-model="sort" size="is-small">
              <option
                v-for="(option, i) in sortOptions"
                :key="i"
                :value="option.val"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <b-field horizontal label="Duration" custom-class="is-small">
            <b-select placeholder="Select" v-model="duration" size="is-small">
              <option
                v-for="(option, i) in durationOptions"
                :key="i"
                :value="option.val"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </section>

    <section>
      <b-loading v-model="loading"></b-loading>

      <section v-if="loading" class="video-results">
        <article class="media" v-for="i in skeleton" :key="i">
          <figure class="media-left">
            <p class="image">
              <b-skeleton width="150px" height="92px"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content result-item--content">
              <p>
                <b-skeleton active></b-skeleton>
                <b-skeleton height="80px"></b-skeleton>
                <b-skeleton height="10px"></b-skeleton>
              </p>
            </div>
          </div>
        </article>
      </section>

      <div v-if="noResults">
        <b-notification
          has-icon
          type="is-info is-light"
          aria-close-label="Close notification"
        >
          {{ $lang.NO_RESULTS }}
        </b-notification>
      </div>

      <div v-if="apiError">
        <b-notification
          has-icon
          type="is-warning is-light"
          aria-close-label="Close notification"
        >
          {{ $lang.API_ERROR }}
          {{ error }}
        </b-notification>
      </div>

      <div v-if="items.length && !loading" class="video-results">
        <div class="result-item" v-for="(item, i) in items" :key="i">
          <a
            :href="item.url"
            target="_blank"
            class="result-item--thumbnail"
            :style="{ 'background-image': `url(${item.thumbnail})` }"
          >
            <!--<img class="result-item--image" :src="item.thumbnail" alt="">-->
            <div class="result-item--duration is-size-7">
              {{ item.duration | durationFormat }}
            </div>
          </a>
          <div class="result-item--content">
            <a
              class="result-item--title break-text has-text-dark"
              :href="item.url"
              target="_blank"
              rel="noopener noreferrer nofollow"
              >{{ item.title | trimTitle }}</a
            >
            <div
              class="result-item--desc break-text is-hidden-mobile has-text-primary mt-1"
            >
              {{ item.url | trimDesc }}
            </div>
            <div class="result-item--desc break-text mt-1">
              {{ item.description | trimDesc }}
            </div>
            <div class="result-item--dt has-text-grey is-size-7 mt-2">
              {{ item.dt | dateTimeFormat }}
            </div>
          </div>
        </div>

        <b-pagination
          class="pt-4"
          v-if="items.length"
          :total="pagesTotal"
          v-model="currentPage"
          range-before="1"
          range-after="4"
          :per-page="itemsPerPage"
          icon-prev="chevron-left"
          icon-next="chevron-right"
          @change="pageChanged"
        >
        </b-pagination>
      </div>

      <div class="powered-by" v-if="items.length">
        <a href="https://www.peteyvid.com" target="_blank"
          ><img
            class="pv-logo"
            width="150"
            height="63"
            :src="require('@/assets/images/powered-by-peteyvid.png')"
            alt="Powered By PeteyVid"
        /></a>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VideoResults",
  data: () => ({
    loading: false,
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    resultsTotal: 0,
    sortOptions: [
      { name: "None", val: "" },
      { name: "Newest First", val: "new" },
      { name: "Oldest First", val: "old" },
      { name: "Longest First", val: "long" },
      { name: "Shortest First", val: "short" },
    ],
    durationOptions: [
      { name: "Any", val: "" },
      { name: "Short (<5m)", val: "S" },
      { name: "Medium (5-20m)", val: "M" },
      { name: "Long (+20m)", val: "L" },
      { name: "Feature (+45m)", val: "F" },
    ],
    sort: "",
    duration: "",
    skeleton: 5,
    noResults: false,
    apiError: false,
    error: null,
    query: null,
  }),
  methods: {
    pageChanged() {
      this.$eventBus.$emit("scrollToTop");
      this.renderResults(this.query);
    },
    async renderResults(query) {
      if (!query) return;
      this.query = query;
      this.loading = true;
      const url = process.env.VUE_APP_VIDEO_API_URL;

      let params = {
        q: encodeURIComponent(this.query),
        sort: this.sort,
        offset: this.offset,
        dur: this.duration,
      };

      this.noResults = false;
      this.apiError = false;

      let result;
      try {
        result = await axios.get(url, { params });
        result = result.data;
        if (!result.items || !result.items.length) {
          this.noResults = true;
          this.items = [];
          this.resultsTotal = 0;
        } else {
          this.resultsTotal = result.results;
          this.items = result.items;
        }
        this.$matomo && this.$matomo.trackEvent('searchResults', 'resultsRendered', 'Video', 'Peteyvid');
      } catch (error) {
        this.error = error;
        this.apiError = true;
        this.items = [];
        this.resultsTotal = 0;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    pagesTotal() {
      return this.resultsTotal / this.itemsPerPage;
    },
  },
  watch: {
    sort() {
      this.currentPage = 1;
      this.$eventBus.$emit("scrollToTop");
      this.renderResults(this.query);
    },
    duration() {
      this.currentPage = 1;
      this.$eventBus.$emit("scrollToTop");
      this.renderResults(this.query);
    },
  },
};
</script>
<template>
  <div>

    <section>

        <b-loading v-model="loading"></b-loading>
        <web-search-skeleton v-if="loading" />

      <div v-if="showErrors">
        <div v-if="noResults">
            <b-notification has-icon type="is-info is-light" aria-close-label="Close notification">
            {{$lang.NO_RESULTS}}
            </b-notification>
        </div>

        <div v-if="apiError">
            <b-notification has-icon type="is-warning is-light" aria-close-label="Close notification">
            {{$lang.API_ERROR}} 
            {{error}}
            </b-notification>
        </div>
      </div>

      <div v-if="items.length && !loading">

        <div class="result-item" v-for="(item,i) in paginatedItems" :key="i">

          <div class="result-item--content">
            <a class="result-item--title break-text " :href="item.url" target="_blank" rel="noopener noreferrer nofollow">{{item.link}}</a>
            <div class="result-item--url break-text is-hidden-mobile">{{item.url}}</div>
            <div class="result-item--desc break-text">{{item.desc}}</div>
          </div>

        </div>

        <b-pagination
          class="pt-4"
          v-if="items.length"
          :total="total"
          v-model="currentPage"
          range-before="1"
          range-after="4"
          :per-page="itemsPerPage"
          icon-prev="chevron-left"
          icon-next="chevron-right"
          @change="pageChanged"
          >
        </b-pagination>

      </div>


    </section>    
  </div>
</template>


<script>
import axios from 'axios';
import WebSearchSkeleton from '@/components/WebSearchSkeleton'

export default {
name: "InternalWebResults",
components: {
    WebSearchSkeleton
},
  data: () => ({
    loading: false,
    loadingElapsedTime: 0,
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    resultsTotal: 0,
    noResults: false,
    apiError: false,
    showErrors: false,
    error: null,
    fauxLoadTime: 1000,
    validResultThreshold: 10
  }),
  methods:{
    getValidResults(items){
      return items.filter(item => item.url.indexOf('http') > -1);
    },
    setNoResults(){
      this.items = [];
      this.resultsTotal = 0;
      this.noResults = true;
      this.$emit('no-results', true);
    },
    async renderResults(query){

      if(!query) return;
      this.loading = true;
      const url = process.env.VUE_APP_SEARCH_API_URL;
      let params = {
        q: encodeURIComponent(query)
      }
      this.noResults = false;
      this.apiError = false;


      let loadtimeInterval = setInterval(()=>{
        this.loadingElapsedTime += 1000;
      }, 1000)

      let result;

      try{
          result = await axios.get(url, {timeout: process.env.VUE_APP_INT_SEARCH_TIMEOUT, params});
          let items = result.data;

          if(typeof items !== 'object'){
            throw("Invalid JSON returned from API")
          }

          if(!items || !items.length){
            this.setNoResults();
          }else{
            items = this.getValidResults(items);
            if(items.length > this.validResultThreshold){
              this.resultsTotal = items.length;
              this.items = items;
              this.$matomo && this.$matomo.trackEvent('searchResults', 'resultsRendered', 'Web', 'Internal');
            }else{
              this.setNoResults();
            }
          }
      }catch(error){
        this.$emit('no-results', true);
        console.error(error);
        this.error = error;
        this.items = [];
        this.resultsTotal = 0;
        this.apiError = true;
      }finally{
          clearInterval(loadtimeInterval);
          this.loadingElapsedTime = 0;
          this.loading = false;
      }
    },
    pageChanged(){
      this.$eventBus.$emit('scrollToTop');
      this.loading = true;
      setTimeout(()=>{
        this.loading = false;
      }, this.fauxLoadTime)
    }
  },
  computed:{
    paginatedItems() {
      let pageNum = this.currentPage-1;
      return this.items.slice(pageNum * this.itemsPerPage, (pageNum + 1) * this.itemsPerPage);
    },
    total() {
      return this.items.length
    },
  },  
 
}
</script>
